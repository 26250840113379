<template>
  <div>
     <LandingHeader />
      <DocumentsComponent type="landing" />
    </div>
</template>

<script>
import DocumentsComponent from '@/components/documents/DocumentsComponent.vue';
import LandingHeader from '@/components/layout/LandingHeader.vue';

export default {
  name: 'Documents',
  components: {
    DocumentsComponent,
    LandingHeader
  },
};
</script>

<style scoped>

</style>
